import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import Earn from 'images/blogs/blog-20.png'
import 'components/css/stories.css'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/3WaysToEarnUsingDigipay`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: '3‌ Ways To Earn Using Digipay',
  },
  {
    property: 'og:description',
    content: 'Alamin kung anu-ano ang extra income na makukuha mo sa Digipay!',
  },
  {
    property: 'og:image',
    content: Earn,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet title="3‌ Ways To Earn Using Digipay" meta={SITE_META}>
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Earn} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          3‌ WAYS TO EARN USING DIGIPAY
        </Heading>

        <Section mb="sp4">
          <Text>
            Are you looking for ways to earn extra income? Bilang isang Digipay
            Agent, kayang-kaya mong ma-achieve ‘yan! Sa bawat transaction,
            sigurado ang kita dahil mayroon itong mahigit 300 billers for bills
            payment, e-load, gaming pins, mobile money at microinsurance. Here
            are 3 things you can earn using your Digipay app:
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            G! SA CONVENIENCE FEE
          </Heading>
          <Text>
            From the word itself, ang binabayaran ng customer ay ang convenience
            na nakukuha nila sa pagbabayad sa Digipay. Hindi na nila kailangan
            pumunta sa mismong biller para pumila at pisikal na magbayad. Isang
            Slide-to-Pay mo lang, bayad na agad! Ang standard na convenience fee
            sa Digipay ay P5 hanggang P15. Maliit man pero ‘pag pinagsama-sama,
            G na G ang kita!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            REBATE IS YOUR BEST MATE
          </Heading>
          <Text>
            Yes, tama ka diyan! Bukod sa convenience fee, may rebates ka pang
            kikitain in every transaction. Hindi mahirap kumita sa Digipay dahil
            lahat ng iyong kapitbahay ay siguradong kailangan ng magload o
            magbayad ng bills kada buwan. Kaya i-chika mo na ‘yan sa kanila! Ang
            rebate na iyong makukuha ay depende sa amount ng iyong transaction.
            Ang saya mag-negosyo with Digipay, ‘di ba?
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            REFERRAL CODE INTO GOLD
          </Heading>
          <Text>
            Want P100 referral fee for you and your beshie? Simple lang ‘yan!
            Invite your friends to sign-up as Digipay Agent using your referral
            code. Kapag successful ang kanilang application, kailangan lang nila
            magtop-up ng minimum P1,000. Just wait for a few days and Digipay
            will credit the P100 in your account. Ang referral code ay makikita
            sa Digipay website. Just log-in, click ‘Account’, and copy the
            8-digit code. Oh ‘di ba, kumita ka pa!
          </Text>
        </Section>

        <Section mb="sp4">
          <Text>
            To get the latest updates on promos and announcements, just like our{' '}
            <a
              className="inline-link"
              href="https://www.facebook.com/digipayph/"
            >
              Facebook
            </a>{' '}
            page, follow us on{' '}
            <a
              className="inline-link"
              href="https://www.instagram.com/digipayph/"
            >
              Instagram
            </a>
            , and visit our{' '}
            <a
              className="inline-link"
              href={`https://${process.env.GATSBY_REACT_APP_HOSTNAME}`}
            >
              website
            </a>
            . And don’t forget to join the{' '}
            <a
              className="inline-link"
              href="https://www.facebook.com/groups/Digipayagents/"
            >
              Digipay Agent Group
            </a>{' '}
            if you’re an existing agent.
          </Text>
          <Text>Hanggang sa muli, ka-Digipay!</Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)

export default ReasonsWhy
